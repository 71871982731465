import React from 'react';
import { Link } from 'gatsby';
import compact from 'lodash/compact';

import { CityLinkFragment } from '../../../graphql.schema';
import Divider from '../Divider';

import * as styles from './index.module.scss';

type CityListProps = {
  mainHeading: string;
  cities: CityLinkFragment[];
  slugBase: string;
};

const CityList = ({ mainHeading, cities, slugBase }: CityListProps) => (
  <div className={styles.cityListWrapper}>
    <div className="columns">
      <div className="column is-9 is-offset-1">
        <h2 className="title is-2 brand-a mb-2">{mainHeading}</h2>
      </div>
    </div>

    <Divider paddingTop="20px" marginBottom="50px" />
    <div className="columns">
      <div className="column is-11 is-offset-1">
        <div className={styles.cityListGrid}>
          {compact(cities).map(({ name, slug }, i: number) => (
            <Link className="mb-3" key={`${name}-${i}`} to={`${slugBase}-${slug}`}>
              {name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default CityList;

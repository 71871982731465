import React from 'react';
import path from 'path-browserify';
import classnames from 'classnames';
import { compact } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import { StrapiCertificationCTA } from '../../components/StrapiComponents/StrapiCertificationCTA';
import { StrapiLocationPanel } from '../../components/StrapiComponents/StrapiLocationPanel';
import HeroComponent from '../../components/HeroComponent/HeroComponent';
import CityList from '../../components/CityList';
import { getMapMarkersFromSchools } from '../../lib/helpers';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { StrapiLevelsComponent } from '../../components/StrapiComponents/StrapiLevelsComponent';
import { StrapiReviewsIOComponent } from '../../components/StrapiComponents/StrapiReviewsIOComponent';
import {
  SingleCertificationPageQuery,
  InlineCtaComponentFragment,
  CertificationCtaFragment,
  EnglishLevelsFragment,
  SchoolFragment,
  MapFragment,
} from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './certification-post.module.scss';

type PageContextType = GlobalPageContext & {
  id: string;
};

const CertificationPage: React.FC<PageProps<SingleCertificationPageQuery, PageContextType>> = ({
  data,
  pageContext,
}) => {
  const { certification } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const showFooterCta = certification?.pageSettings?.showFooterCta ?? false;
  const footerCta = certification?.pageSettings?.customFooterCta?.id
    ? certification?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;
  const inPersonExamMarkers = getMapMarkersFromSchools(compact(certification?.examSchools));

  const mapData = {
    defaultZoom: 6,
    markers: inPersonExamMarkers,
    width: '100%',
    height: '490px',
  } as MapFragment;

  if (!websiteLocale || !certification) return <p>Not found</p>;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={certification?.seo?.metaTitle ?? certification.pageSettings?.title ?? certification.name ?? ''}
        description={certification?.seo?.metaDescription ?? certification.pageSettings?.metaDescription}
        image={certification?.seo?.metaImage?.url}
        avoidIndexing={certification?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={certification?.seo?.canonicalURL}
        keywords={certification?.seo?.keywords}
        metaSocial={certification?.seo?.metaSocial}
        structuredData={certification?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />

      <div className={classnames(styles.main, 'container')}>
        <Breadcrumb pageContext={pageContext} lastCrumb={certification.name ?? ''}></Breadcrumb>
        <HeroComponent
          title={certification.name ?? ''}
          image={certification.image}
          intro={certification.heroBody}
          button={certification.heroLink}
        />
      </div>
      <StrapiCertificationCTA {...(certification.certificationCta as CertificationCtaFragment)} />
      <div className="container">
        {certification.englishLevels && (
          <StrapiLevelsComponent {...(certification.englishLevels as EnglishLevelsFragment)} />
        )}
        {compact(certification.examSchools).length ? (
          <StrapiLocationPanel
            schools={certification.examSchools as SchoolFragment[]}
            map={mapData}
            title={certification.examSchoolsHeading ?? ''}
            {...certification}
          />
        ) : null}
        {compact(certification.cities).length ? (
          <CityList
            slugBase={
              pageContext.breadcrumb?.location ??
              path.join(pageContext.pathPrefixes.certifications, certification.slug ?? '')
            }
            mainHeading={certification.cityHeadingText ?? ''}
            cities={compact(certification.cities)}
          />
        ) : null}
      </div>
      <div className={classnames('page-components', styles.certificationComponents)}>
        {certification.components?.map((component, idx) =>
          component ? (
            <ComponentRenderer
              component={component}
              key={`page-component-${certification.id}-${component.__typename}-${idx}`}
            />
          ) : null,
        )}
      </div>
      {certification.reviewsWidget ? <StrapiReviewsIOComponent {...certification.reviewsWidget} /> : null}
      {certification?.footerCta ? (
        <StrapiInlineCtaComponent {...(certification.footerCta as InlineCtaComponentFragment)} />
      ) : (
        showFooterCta && footerCta && <StrapiFooterCtaComponent {...footerCta} />
      )}
    </LayoutWrapper>
  );
};

export const query = graphql`
fragment CertificationCta on Strapi_CertificationCta {
  columnOneHeading: column_one_heading
  columnOneBody: column_one_body
  columnOneLink: column_one_link {
    ...LinkComponent
  }
  columnTwoHeading: column_two_heading
  columnTwoBody: column_two_body
  columnTwoLink: column_two_link {
    ...LinkComponent
  }
}

query SingleCertificationPage($id: ID!) {
  strapi {
    certification(id: $id) {
      data {
        id
        attributes {
          name
          slug
          excerpt
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          image {
            ...StrapiImageEntityResponse
          }
          heroBody: hero_body
          heroLink: hero_link {
            ...LinkComponent
          }
          englishLevels: certification_english_levels {
            ...EnglishLevels
          }
          certificationCta: certification_cta {
            data {
              id
              attributes {
                ...CertificationCta
              }
            }
          }
          reviewsWidget: reviews_widget {
            ...ReviewsIOComponent
          }
          examSchools: exam_schools(pagination: {limit: -1}) {
            data {
              id
              attributes {
                ...School
              }
            }
          }
          cityHeadingText: city_heading_text
          examSchoolsHeading: exam_schools_heading
          footerCta: footer_cta {
            ...InlineCTAComponent
          }
          cities(pagination: {limit: -1}, sort: "name:asc") {
            data {
              id
              attributes {
                ...CityLink
              }
            }
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          components {
            ...PageComponents
            ... on Strapi_ComponentPageTrustPilotWidget {
              heading
              script {
                ...HTMLComponent
              }
            }
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(CertificationPage));

import React from 'react';

import { CertificationCtaFragment, LinkComponentFragment } from '../../../../graphql.schema';
import { RichTextField } from '../../RichTextField/RichTextField';
import StrapiButton from '../../StrapiLink/StrapiButton';

import * as styles from './index.module.scss';

export const StrapiCertificationCTA = ({
  columnOneHeading,
  columnOneBody,
  columnOneLink,
  columnTwoHeading,
  columnTwoBody,
  columnTwoLink,
}: CertificationCtaFragment) => (
  <div className={styles.certificationWrapper}>
    <div className="container">
      <div className="columns">
        <div className="column is-5">
          <h2 className="title is-2 brand-a">{columnOneHeading}</h2>
          <RichTextField className="black-2 is-text-2 mb-5" content={columnOneBody} />
          <StrapiButton {...(columnOneLink as LinkComponentFragment)} type="secondary" />
        </div>
        <div className="column is-5 is-offset-1">
          <h2 className="title is-2 brand-a">{columnTwoHeading}</h2>
          <RichTextField className="black-2 is-text-2 mb-5" content={columnTwoBody} />
          <StrapiButton {...(columnTwoLink as LinkComponentFragment)} type="secondary" />
        </div>
      </div>
    </div>
  </div>
);
